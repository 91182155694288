/* @tailwind base;
@tailwind components;
@tailwind utilities;

input:checked + div {
  @apply border-blue-500;
}
input:checked + div svg {
  @apply block;
} */

/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 14px;
    color: #7c8ba1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-items: center;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 3px;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #fa7;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #e62;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .c-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 24px;
    color: #7c8ba1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-items: center;
  }
  
  /* Hide the browser's default checkbox */
  .c-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .c-checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 3px;
  }
  
  /* On mouse-over, add a grey background color */
  .c-container:hover input ~ .c-checkmark {
    background-color: #fa7;
  }
  
  /* When the checkbox is checked, add a blue background */
  .c-container input:checked ~ .c-checkmark {
    background-color: #e62;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .c-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .c-container input:checked ~ .c-checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .c-container .c-checkmark:after {
    left: 6px;
    top: 2px;
    width: 9px;
    height: 18px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


  @media screen and (max-width: 992px) {
    .mt-2 {
      text-align: left;
    }
  }